import {Component, Inject, Vue, Watch} from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfStore from "@/vue/domain/store/df-store";
import Firebase from "@/vue/infrastructure/utils/firebase";
import DfFirebaseTopicType from "@/vue/domain/firebase/df-firebase-topic-type";
import DfBrand from "@/vue/domain/brand/df-brand";
import vuescroll from "vuescroll";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfPaging from "@/vue/domain/paging/df-paging";

@Component({
  components: {
    vuescroll,
  },
  filters: {
    adderessFilter: (address: string, city: string) => {
      const separetor: string = address && city ? " - " : "";
      return `${address || ""}${separetor}${city || ""}`;
    },
  },
})
export default class DfStoreSelectionComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  private searchQuery: string = null;

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get stores(): Array<DfStore> {
    return this.$store.getters.stores;
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  set currentStore(currentStore: DfStore) {
    this.$store.dispatch("setCurrentStore", currentStore);
  }

  get geolocationEnabled(): boolean {
    return this.$store.getters.geolocationEnabled;
  }

  set geolocationEnabled(geolocationEnabled: boolean) {
    this.$store.dispatch("setGeolocationEnabled", geolocationEnabled);
  }

  get storesPaging(): DfPaging {
    return this.$store.getters.storesPaging;
  }

  @Watch("searchQuery")
  private searchQueryChanged(newValue: string, oldValue: string) {
    const rule01: boolean = this.searchQuery && this.searchQuery.length >= 3;
    const rule02: boolean = !newValue && !!oldValue;

    if (rule01 || rule02) {
      this.$root.$emit("loadStores", null, this.searchQuery);
    }
  }

  private isCurrentStore(store: DfStore): boolean {
    return this.currentStore ? this.currentStore.identifier === store.identifier : false;
  }

  private storeSelection(store: DfStore) {
    this.currentStore = store;

    if (store) {
      Utils.setAnaliticsTraker({
        event: "change-store",
        origin: "smt-digitalflyer-ui",
        storeAlias: this.currentStore.alias,
        storeCode: this.currentStore.code,
        storeName: this.currentStore.name,
      });
    }

    if (this.$store.getters.initialRoute) {
      this.$router.push({
        name: (this.$store.getters.initialRoute.meta || {}).redirectTo || this.$store.getters.initialRoute.name || "home-store",
        params: Object.assign({}, this.$store.getters.initialRoute.params, { storeAlias: store.alias }),
        query: this.$store.getters.initialRoute.query,
      });
      this.$store.dispatch("setInitialRoute", null);
    } else {
      if (this.$route.params.storeAlias !== store.alias) {
        if (this.$route.name === "promotion" || this.$route.name === "product") {
          this.$router.push({
            name: this.$route.name,
            params: Object.assign({}, this.$route.params, { storeAlias: store.alias }),
            query: this.$route.query,
          });
        } else {
          this.$router.push({ name: "home-store", params: { storeAlias: store.alias } });
        }
      }
    }
  }

  private resetSearchQuery() {
    this.searchQuery = null;
    this.$root.$emit("loadStores");
  }

  private toggleGeolocation() {
    this.geolocationEnabled = !this.geolocationEnabled;
    Utils.localStorageSetItem(Utils.LOCAL_STORAGE_GEO_LOCALIZATION, this.geolocationEnabled.toString());
  }

  private handleStoresScroll(vertical: any, horizontal: any, nativeEvent: any) {
    if (Math.ceil(nativeEvent.target.scrollTop) + nativeEvent.target.clientHeight >= nativeEvent.target.scrollHeight) {
      if (this.storesPaging && !this.storesPaging.last) {
        this.storesPaging.number++;
        this.$root.$emit("loadStores", this.storesPaging);
      }
    }
  }
}
