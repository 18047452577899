import { Component, Inject, Vue } from "vue-property-decorator";
import { CONTAINER_TYPES } from "@/vue/infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfStore from "@/vue/domain/store/df-store";
import Swal from "sweetalert2";

@Component({
  components: {
    DfLoadingMask: () => import(/* webpackChunkName: 'df-loading-mask' */ "@/vue/components/df-loading-mask/df-loading-mask.vue"),
  },
})
export default class DfFormEmailChangeComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  private oldEmail: string = null;
  private newEmail: string = null;
  private confirmEmail: string = null;
  private formError: any = {};
  private working = false;

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  private changeEmail() {
    this.formError = [];
    this.working = true;
    this.service
      .changeEmail({oldEmail: this.oldEmail, newEmail: this.newEmail, confirmEmail: this.confirmEmail, storeIdentifier: this.currentStore.identifier})
      .then((response: any) => {
        Swal.fire({
          title: this.$t("df-form-email-change.modal.save.title").toString(),
          text: this.$t("df-form-email-change.modal.save.body").toString(),
          icon: "info",
          confirmButtonColor: "#3085d6",
          confirmButtonText: this.$t("df-form-email-change.modal.save.ok").toString(),
        }).then((result) => {
          if (result.value) {
            this.$emit("changedEmail");
            this.working = false;
          }
        });
      })
      .catch((error: any) => {
        this.working = false;
        if (error.response.data.errors) {
          this.formError = [];
          error.response.data.errors.map((errorData: any) => {
            this.formError[errorData.field] = `${errorData.objectName}-${errorData.code}`;
            console.log(`SMT: ${errorData.objectName}-${errorData.code}`, this.$t(`${errorData.objectName}-${errorData.code}`));
          });
        } else {
          Utils.defaultApiErrorHandler(error, this);
        }
      });
  }

  private showForgottenEmail() {
    this.$emit("showForgottenEmail");
  }
}
