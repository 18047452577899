import {Component, Inject, Vue} from "vue-property-decorator";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfStore from "@/vue/domain/store/df-store";
import {CONTAINER_TYPES} from "@/vue/infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";

const DfHeaderMobileComponent = () =>
  Utils.externalComponent2("df-header-mobile");
const DfStoreCardComponent = () => Utils.externalComponent2("df-store-card");

@Component({
  components: {
    DfHeaderMobile: DfHeaderMobileComponent,
    DfStoreCard: DfStoreCardComponent,
    DfLoadingMask: () => import(/* webpackChunkName: 'df-loading-mask' */ "@/vue/components/df-loading-mask/df-loading-mask.vue"),
  },
})
export default class DfPageAccountCoupon extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  private showCoupon = false;
  private galleryLoading = true;

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  beforeMount() {
    if (Utils.enableSaviCoupon()) {
      this.showCoupon = true;
    } else {
      this.$router.push({ name: "home-store", params: { storeAlias: this.currentStore.alias } });
    }
  }

  mounted() {
    if (Utils.enableSaviCoupon()) {
      const newScript = document.createElement("script");
      newScript.src = Utils.getSaviCouponScriptUrl();
      this.$el.appendChild(newScript);
      //(<any>window).valassis.expand(); //funziona solo la prima volta
    }
  }

  private getConsumerData(loginRequired: boolean, setConsumerData: any) {
    console.log("SMT: Call getConsumerData internal");
    setConsumerData("[Ask savi for this value]");
  }

  private galleryLoaded(data: any) {
    console.log("SMT: Call galleryLoaded internal", data);
    this.galleryLoading = false;
  }

/*
  private getConsumerData(loginRequired: boolean, setConsumerData: string) {
    if (confirm('Would you like to provide login data?')) {
      setConsumerData('[Ask savi for this value]');
      document.getElementById('consumerLoginStatus').innerText = 'logged-in';
    } else if (loginRequired) {
      alert('Printing is not possible');
    }
  }
*/
}
