class Firebase {
  public static grantPermission() {
    if ((<any>window).FirebasePlugin) {
      (<any>window).FirebasePlugin.grantPermission((hasPermission: boolean) => {
        console.log("SMT: Permission was " + (hasPermission ? "granted" : "denied"));
      });
    } else {
      console.log(`SMT: FirebasePlugin is not loaded: no granted permission`);
    }
  }

  public static subscribe(topicName: string) {
    if ((<any>window).FirebasePlugin) {
      (<any>window).FirebasePlugin.subscribe(
        topicName,
        () => {
          console.log(`SMT: Subscribed to the "${topicName}" topic`);
        },
        (error: any) => {
          console.log(`SMT: Failed to subscribe to the "${topicName}" topic`, error);
        }
      );
    } else {
      console.log(`SMT: FirebasePlugin is not loaded: subscribe`);
    }
  }

  public static unsubscribe(topicName: string) {
    if ((<any>window).FirebasePlugin) {
      (<any>window).FirebasePlugin.unsubscribe(
        topicName,
        () => {
          console.log(`SMT: Unsubscribed to the "${topicName}" topic`);
        },
        (error: any) => {
          console.log(`SMT: Failed to unsubscribe to the "${topicName}" topic`, error);
        }
      );
    } else {
      console.log(`SMT: FirebasePlugin is not loaded: unsubscribe`);
    }
  }

  public static token() {
    if ((<any>window).FirebasePlugin) {
      (<any>window).FirebasePlugin.getToken(
        (fcmToken: any) => {
          console.log(`SMT: Token messaging ${fcmToken}`);
          return fcmToken;
        },
        (error: any) => {
          console.error(error);
        }
      );
    } else {
      console.log(`SMT: FirebasePlugin is not loaded: allSubscribeTopic`);
    }
  }

  public static getId() {
    if ((<any>window).FirebasePlugin) {
      (<any>window).FirebasePlugin.getId(
        (appInstanceId: any) => {
          console.log(`SMT: App firebase instance id: ${appInstanceId}`);
        },
        (error: any) => {
          console.log(`SMT: Failed to get app firebase instance id`, error);
        }
      );
    } else {
      console.log(`SMT: FirebasePlugin is not loaded`);
    }
  }

  public static getToken() {
    if ((<any>window).FirebasePlugin) {
      (<any>window).FirebasePlugin.getToken(
        (token: any) => {
          console.log(`SMT: App firebase token is: ${token}`);
        },
        (error: any) => {
          console.log(`SMT: Failed to get app firebase token`, error);
        }
      );
    } else {
      console.log(`SMT: FirebasePlugin is not loaded: getToken`);
    }
  }

  public static onMessageReceived(handleNotificationMessage: any, handleDataMessage: any) {
    if ((<any>window).FirebasePlugin) {
      console.log("SMT: Firebase onMessageReceived register handlers");
      (<any>window).FirebasePlugin.onMessageReceived(
        (message: any) => {
          try {
            console.log("SMT: Firebase Message Received");
            console.dir(message);
            if (message.messageType === "notification") {
              handleNotificationMessage(message);
            } else {
              handleDataMessage(message);
            }
          } catch (e) {
            console.log("SMT: Exception in onMessageReceived callback: " + e.message);
          }
        },
        function (error: any) {
          console.log("SMT: Failed receiving FirebasePlugin message", error);
        }
      );
    } else {
      console.log(`SMT: FirebasePlugin is not loaded: onMessageReceived`);
    }
  }

  public static sendNonFatalError(error: any) {
    (<any>window).FirebasePlugin.logError(
      `smt_internal_error: ${JSON.stringify(error)}`,
      function () {
        console.log("SMT: Sent non-fatal error");
      },
      function (error: any) {
        console.log("SMT: Failed to send non-fatal error", error);
      }
    );
  }
}

export default Firebase;
